//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { ar } from "vue2-datepicker/locale/ar";
import { en } from "vue2-datepicker/locale/en";
import FindSharePriceComponent from '@/components/FindSharePrice/FindSharePrice.vue'
import moment from 'moment'
export default {
    name: 'ContactUs',
    components: { DatePicker, ar, en, FindSharePriceComponent },
    data: function () {
        let self = this;
        return {
            query: {
                Filter: [],
                PageNumber: 1,
                PageSize: 2,
            },
            baseUrl: process.env.VUE_APP_APIEndPoint,
            showValidMessage: false,
            successmsg: self.$t('savedSucess'),
            warnmesg: self.$t('warnmsg'),
            ViewMode: 1,
            chart: null,
            filterModel: {
                PeriodFromDate: moment(new Date()).subtract(1, "years").subtract(1, "days").locale("en").format('YYYY-MM-DD HH:mm'),
                PeriodToDate: moment(new Date()).subtract(1, "days").locale("en").format('YYYY-MM-DD HH:mm')
            },
            model: {

            },
            IsCheck: false,
            showPopup: false
        }
    },
    methods: {
        ShowLoading: function () {
            $("body").addClass("active-loader");
        },
        HideLoading: function () {
            $("body").removeClass("active-loader");
        },
        colsePopup: function () {
            this.showPopup = false;
        },
        openTab: function (evt, tabName) {
            var self = this;
            self.tabName = tabName;
            // Declare all variables
            var i, tabcontent, tablinks;

            // Get all elements with class="tabcontent" and hide them
            tabcontent = document.getElementsByClassName("tabcontent");
            for (i = 0; i < tabcontent.length; i++) {
                tabcontent[i].style.display = "none";
            }

            // Get all elements with class="tablinks" and remove the class "active"
            tablinks = document.getElementsByClassName("tablinks");
            for (i = 0; i < tablinks.length; i++) {
                tablinks[i].className = tablinks[i].className.replace(" active", "");
            }

            // Show the current tab, and add an "active" class to the button that opened the tab
            document.getElementById(tabName).style.display = "block";
            evt.currentTarget.className += " active";
        },
        Print: function () {

            var self = this;
            self.query.Filter = [];

            if (self.filterModel.PeriodFromDate && self.filterModel.PeriodFromDate != "") {
                self.query.Filter.push({
                    FieldName: "FromDate",
                    Operation: "=",
                    value: moment(self.filterModel.PeriodFromDate).locale("en").format('YYYY-MM-DD HH:mm'),
                });
            }
            if (self.filterModel.PeriodToDate && self.filterModel.PeriodToDate != "") {
                self.query.Filter.push({
                    FieldName: "ToDate",
                    Operation: "=",
                    value: moment(self.filterModel.PeriodToDate).locale("en").format('YYYY-MM-DD HH:mm'),
                });
            }

            self.query.Language = self.$i18n.locale;
            self.axios.post(process.env.VUE_APP_APIEndPoint + 'SharePriceInquiry/PrintSharePriceInquiryReport', self.query, self.headers)
                .then(function (res) {
                    var link = document.createElement('a');
                    link.href = "data:application/octet-stream;base64," + res.data;
                    link.target = '_blank';
                    link.download = self.$t('SharePriceInquiryReport') + ".xlsx";
                    link.click();
                    link.remove();  //afterwards we remove the element again
                })
                .catch(function (err) {

                    return console.log(err);
                }).finally(function () {
                });
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        onSubmit: function () {
            alert("Any thing");
            console.log("Any thing");
        },
        disableDate(date) {
            return date > new Date();
        },

        showWedgit: function () {
            let self = this;
            self.IsCheck = true;
        },
        notify: function (message, fn = "error") {
            let self = this;
            self.$snotify.clear();
            if (fn == "success") {
                self.$snotify.success(message, {
                    timeout: 3000,
                    showProgressBar: true,
                    //closeOnClick: false,
                    pauseOnHover: true
                });
            }
            else if (fn == "warning") {
                self.$snotify.warning(message, {
                    timeout: 3000,
                    showProgressBar: true,
                    //closeOnClick: false,
                    pauseOnHover: true
                });
            }
            else {
                self.$snotify.html(message, {
                    type: "error",
                    timeout: 3000,
                    showProgressBar: true,
                    //closeOnClick: false,
                    pauseOnHover: true
                });
            }
        },
        ShowLoading: function () {
            $("body").addClass("active-loader");
        },
        HideLoading: function () {
            $("body").removeClass("active-loader");
        },
        ConvertToJson: function (str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return "";
            }
            return JSON.parse(str);
        },
        displayError: function (err) {
            var self = this;
            if (err?.response?.status == "401") {
                return;
            }
            if (err?.response?.data?.type && err?.response?.data?.type.toLowerCase() == "business") {
                //let messages = err?.response?.data?.message;
                var messages = self.ConvertToJson(err?.response?.data?.message);
                if (messages != "") {
                    let errorstr = "";
                    messages.map(x => errorstr += self.$t(x.MemberNames) + ' ' + self.$t(x.ErrorMessage) + "<br/>");
                    self.notify(errorstr, "error")
                }
                else {
                    self.notify(self.$t("ConnectionError"), "error");
                }
            }
            else if (err?.response?.data?.type && err?.response?.data?.type.toLowerCase() == "error") {
                var errorMsg = err?.response?.data?.message;
                self.notify(self.$t(errorMsg[Object.keys(errorMsg)[0]]) + "<br/>" + err?.response?.data?.exCode, "error");
            }
            else if (err.length > 0) {
                for (let i = 0; i < err.length; i++) {
                    let errorstr = "";
                    errorstr += err[i].Message + "<br/>";
                    self.notify(errorstr, "error");
                }
            }
            else {
                self.notify(self.$t("ConnectionError"), "error");
            }
        },
        Submit: function () {
            let self = this;
            self.ShowLoading();
            self.query.Filter = [];

            if (self.filterModel.PeriodFromDate && self.filterModel.PeriodFromDate != "") {
                self.query.Filter.push({
                    FieldName: "FromDate",
                    Operation: "=",
                    value: moment(self.filterModel.PeriodFromDate).locale("en").format('YYYY-MM-DD HH:mm'),
                });
            }
            if (self.filterModel.PeriodToDate && self.filterModel.PeriodToDate != "") {
                self.query.Filter.push({
                    FieldName: "ToDate",
                    Operation: "=",
                    value: moment(self.filterModel.PeriodToDate).locale("en").format('YYYY-MM-DD HH:mm'),
                });
            }

            if (self.filterModel.PeriodFromDate && self.filterModel.PeriodToDate) {
                self.axios
                    .post(process.env.VUE_APP_APIEndPoint + "SharePriceInquiry/Calculater", self.query)
                    .then(function (res) {
                        self.model = res.data.Data;
                        self.model.Challange = Math.round(self.model.Challange * 100)/100;//self.model.Challange.toString().slice(0, 5);
                        let data = res.data.Data.ShareChartData;
                        self.showWedgit();

                        // split the data set into ohlc and volume
                        var ohlc = [],
                            volume = [],
                            dataLength = data.length,
                            i = 0;
                        for (i; i < dataLength; i += 1) {
                            ohlc.push([
                                data[i]['data'][0], // the date
                                data[i]['data'][1], // lastTradePrice
                            ]);

                            volume.push([
                                data[i]['data'][0], // the date
                                data[i]['data'][2] // the volumeTraded
                            ]);
                        }



                        self.chart = Highcharts.stockChart('container', {
                            rangeSelector: {
                                enabled: false
                            },
                            stockTools: {
                                gui: {
                                    enabled: false // disable the built-in toolbar
                                }
                            },
                            xAxis: {
                                gridLineWidth: 1,
                            },
                            yAxis: [{
                                opposite: false,
                                labels: {
                                    align: 'right',
                                    x: -3
                                },
                                title: {
                                    text: self.$t('SAR')
                                },
                                height: '60%',
                                lineWidth: 1,
                                shadow: true,
                                resize: {
                                    enabled: true
                                }
                                , events: {
                                    aftersetextremes: function (e) {
                                        self.drawextrema(e.target.chart);

                                    }
                                }
                            }, {
                                opposite: false,


                                labels: {
                                    align: 'right',
                                    x: -3
                                },
                                title: {
                                    text: self.$t('VolumeTraded')
                                },
                                top: '65%',
                                height: '35%',
                                offset: 0,
                                lineWidth: 1,
                                shadow: true,
                            }],
                            tooltip: {
                                shape: 'square',
                                headerShape: 'callout',
                                borderWidth: 1,
                                shadow: true,
                                useHTML: true,
                                formatter: function (refresh) {
                                    let s = '<b>' + Highcharts.dateFormat('%A, %b %e, %Y', this.x) + '</b></br>';

                                    if (this.points) { 

                                    this.points.forEach(point => {
                                        if (point.series.name == 'Sasco') {
                                            s += '<b><span style = "color:' + point.series.color + ';">' + self.$t('SASCO') + ' </b>'
                                                + '<b><span style = "color:' + point.series.color + ';">' + self.$t('Last') + ': ' + point.y.toFixed(2) + '</b></br>';
                                        }
                                        else if (point.series.name == 'Volume') {
                                            s += '<b><span style = "color:' + point.series.color + ';">' + self.$t('Volume') + ': ' + point.y.toFixed(2) + '</b></br>';
                                        }
                                    });

                                        return s;
                                    }
                                }
                            },
                            navigator: {
                                enabled: false
                            },
                            scrollbar: {
                                enabled: false
                            },
                            series: [{
                                type: 'line',
                                id: 'sasco',
                                name: 'Sasco',
                                data: ohlc,
                            }
                                , {
                                type: 'column',
                                id: 'volume',
                                name: 'Volume',
                                data: volume,
                                yAxis: 1
                            }],
                            responsive: {
                                rules: [{
                                    condition: {
                                        maxWidth: 800
                                    },
                                    chartOptions: {
                                        rangeSelector: {
                                            inputEnabled: false
                                        }
                                    }
                                }]
                            }
                        });


                        //call the function initially
                        self.drawEXtrema(self.chart);
                    }).catch(function (err) {
                        self.displayError(err);
                        return console.log(err);
                    }).finally(function () {
                        return self.HideLoading();
                    });
            }

        },
        drawEXtrema: function (chart) {
            debugger;
            var self = this;
            //get the axis and data extremes
            var yExt = chart.yAxis[0].getExtremes();
            var xExt = chart.xAxis[0].getExtremes();
            var maxData = yExt.dataMax;
            var minData = yExt.dataMin;
            var minTime = xExt.min;
            var maxTime = xExt.max;

            var xMin;
            var xMax;
            console.log(yExt);
            console.log(xExt);

            //llop through the data series to find the points matching the high/low

            chart.series[0].points.map(point => {
                if (point.y == maxData && point.x <= maxTime && point.x >= minTime) {
                    xMax = point.x;
                }
                if (point.y == minData && point.x <= maxTime && point.x >= minTime) {
                    console.log(point);

                    xMin = point.x;
                }
            }
            );

            //if the high/low series exist, remove them
            var extHigh = chart.get('extHigh');
            if (extHigh != null) {
                extHigh.remove();
            }
            var extLow = chart.get('extLow');
            if (extLow != null) {
                extLow.remove();
            }

            //add the series to the chart
            chart.addSeries({
                id: 'extHigh',
                type: 'scatter',
                name: 'extHigh',
                color: 'green',
                data: [{ x: xMax, y: maxData }],
                dataLabels: {
                    enabled: true,
                    borderRadius: 5,
                    backgroundColor: 'rgba(252, 255, 197, 0.7)',
                    borderWidth: 1,
                    borderColor: '#AAA',
                    useHTML: true,
                    format: '<b><span style = "color:green;">' + Highcharts.dateFormat('%A, %b %e, %Y', xMax) + '</b></br>' + self.$t('extHigh') + ': ' + maxData.toFixed(2) + '</b></br>'
                }
            });
            chart.addSeries({
                id: 'extLow',
                type: 'scatter',
                color: 'red',
                name: 'extLow',
                data: [{ x: xMin, y: minData }],
                dataLabels: {
                    enabled: true,
                    borderRadius: 5,
                    backgroundColor: 'rgba(252, 255, 197, 0.7)',
                    borderWidth: 1,
                    borderColor: '#AAA',
                    useHTML: true,
                    format: '<b><span style = "border:1px;color:red;">' + Highcharts.dateFormat('%A, %b %e, %Y', xMin) + '</b></br>' + self.$t('extLow') + ': ' + minData.toFixed(2) + '</b></br>'
                }
            });
        }
        ,


        loadData: function () {
            let self = this;
            self.showPopup = true;
            self.showValidMessage = true;
            self.$validator.validate().then(function (result) {
                if (!result) { ////not valid
                    let detail = $('.form-error').first()[0];
                    if (detail) {
                        detail.scrollIntoView({ behavior: 'smooth', block: 'end' });
                        self.notify(self.warnmesg, "warning");

                    }
                    return;
                }
                $('.form-error').removeClass("form-error");
                self.Submit();

            });
        },

    },
    mounted() {
        document.getElementById("defaultOpen").click();
    }
};
